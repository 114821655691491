import * as S from "./style";
import { useParams, Navigate } from "react-router-dom";
import { TransactionValues } from "company/types";
import Question from "components/icons/Question";
import Indicator from "components/Indicator";
import Submenu, { SubmenuItem } from "components/Submenu";
import { getPastAndFutureDateRanges } from "utils/filters";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import {
    defaultTransactionRoute,
    TransactionFilters,
    TransactionType,
} from "./types";
import TransactionView from "./components/TransactionView";
import Filters, { FiltersRef } from "./components/Filters";
import { useRef, useCallback, useMemo, useState } from "react";
import { SelectOption } from "components/Select";
import Title from "components/Title";

const { past, future } = getPastAndFutureDateRanges();
const defaultPagination: Pagination = { perPage: 50, page: 1 };
const defaultSort: SortBy = { column: 0, isAscending: true };

type DateRangeFilter =
    | false
    | {
          options: SelectOption<number>[];
          default: number | undefined;
      };

const getDateConfig = (
    ranges: typeof past | typeof future
): {
    dateRangeFilters: DateRangeFilter;
    date: number | undefined;
} => ({
    dateRangeFilters: ranges.furthest?.value
        ? {
              options: ranges.uniques,
              default: ranges.furthest.value,
          }
        : false,
    date: ranges.furthest?.value ? ranges.furthest.value : undefined,
});

type TransactionConfig = {
    columns: TransactionValues[];
    initialSort: {
        column: number;
        isAscending: boolean;
    };
    pagination: Pagination;
    dateRangeFilters: DateRangeFilter;
    filters: TransactionFilters;
};

const getTransactionConfig = (type?: TransactionType): TransactionConfig => {
    const columns = [
        TransactionValues.dateDue,
        TransactionValues.invoiced,
        TransactionValues.network,
        TransactionValues.allowanceBalance,
        TransactionValues.sender,
        TransactionValues.receiver,
        TransactionValues.itemName,
        TransactionValues.invoice,
        TransactionValues.details,
    ];

    const columnsWithPaidData = [
        TransactionValues.dateDue,
        TransactionValues.datePaid,
        TransactionValues.invoiced,
        TransactionValues.network,
        TransactionValues.received,
        TransactionValues.sender,
        TransactionValues.receiver,
        TransactionValues.itemName,
        TransactionValues.invoice,
        TransactionValues.details,
    ];

    const baseConfig = {
        pagination: defaultPagination,
        initialSort: defaultSort,
        filters: {},
    };

    if (!type || !Object.values(TransactionType).includes(type)) {
        // All transactions
        return {
            ...baseConfig,
            columns: [
                TransactionValues.transactionTypes,
                ...columnsWithPaidData,
            ],
            initialSort: { column: 1, isAscending: true },
            dateRangeFilters: false,
            filters: {},
        };
    }

    switch (type) {
        case TransactionType.CONFIRMED: {
            const { dateRangeFilters, date } = getDateConfig(past);
            return {
                ...baseConfig,
                columns: columnsWithPaidData,
                initialSort: { column: 1, isAscending: false },
                dateRangeFilters,
                filters: { date },
            };
        }
        case TransactionType.CANCELED:
            return {
                ...baseConfig,
                columns,
                dateRangeFilters: false,
                filters: {},
            };
        default: {
            // Upcoming, Due, or Uncollectible transactions
            const range = type === TransactionType.UPCOMING ? future : past;
            const { dateRangeFilters, date } = getDateConfig(range);
            return {
                ...baseConfig,
                columns,
                dateRangeFilters,
                filters: { date },
            };
        }
    }
};

const Transactions = () => {
    const { type } = useParams<{ type: TransactionType }>();
    const prevTypeForPageRef = useRef<TransactionType | undefined>(type);
    const prevTypeForSortRef = useRef<TransactionType | undefined>(type);

    const [userPage, setUserPage] = useState(1);
    const [userSort, setUserSort] = useState<SortBy | undefined>();
    const filtersRef = useRef<FiltersRef>(null);

    const currentType = useMemo(() => {
        if (!type) {
            return undefined;
        }
        if (!Object.values(TransactionType).includes(type as TransactionType)) {
            return "invalid" as const;
        }
        return type as TransactionType;
    }, [type]);

    const config = useMemo(
        () =>
            getTransactionConfig(
                currentType === "invalid" ? undefined : currentType
            ),
        [currentType]
    );

    const page = useMemo(() => {
        if (type !== prevTypeForPageRef.current) {
            prevTypeForPageRef.current = type;
            return 1;
        }
        return userPage;
    }, [type, userPage]);

    const sort = useMemo(() => {
        if (type !== prevTypeForSortRef.current) {
            prevTypeForSortRef.current = type;
            return config.initialSort;
        }
        return userSort || config.initialSort;
    }, [type, userSort, config.initialSort]);

    const [userFilters, setUserFilters] = useState<TransactionFilters>({});

    const dateSetByUser = useRef(false);
    const handleFilterChange = useCallback(() => {
        if (!filtersRef?.current) return;

        const {
            search,
            selectedEntitiesNames,
            selectedNetworksNames,
            dateRange,
        } = filtersRef.current;

        setUserFilters({
            search,
            entities: selectedEntitiesNames.join(","),
            networks: selectedNetworksNames.join(","),
            date: dateRange || undefined,
        });
        dateSetByUser.current = true;
    }, []);

    // // Combine config filters (which include date) with user filters
    const filters = useMemo(() => {
        const date = dateSetByUser.current
            ? userFilters.date
            : config.filters.date;

        dateSetByUser.current = false;
        return {
            ...userFilters,
            date,
        };
    }, [config.filters, userFilters]);

    // Move this to a hook
    const { totalResults: totalDueTransactions } = useGetCompanyTransfers({
        type: TransactionType.DUE,
        limit: 100,
    });

    const submenu: SubmenuItem[] = [
        { to: ``, label: `All`, end: true },
        { to: `confirmed`, label: `Confirmed` },
        { to: `upcoming`, label: `Upcoming` },
        {
            to: `due`,
            label: (
                <>
                    Due
                    {totalDueTransactions ? (
                        <>
                            {" "}
                            <Indicator>
                                {totalDueTransactions > 99
                                    ? `99+`
                                    : totalDueTransactions}
                            </Indicator>
                        </>
                    ) : (
                        ``
                    )}
                </>
            ),
        },
        { to: `uncollectible`, label: `Uncollectible` },
        { to: `canceled`, label: `Canceled` },
    ];

    // Handle invalid type as the URL param
    if (currentType === "invalid") {
        return (
            <Navigate to={`/transactions/${defaultTransactionRoute}`} replace />
        );
    }

    return (
        <>
            <Filters
                ref={filtersRef}
                dateRangeFilters={config.dateRangeFilters}
                onChange={handleFilterChange}
            />
            <S.TypeRow>
                <Submenu data={submenu} name="Transaction types" />
                <S.TxTypeTips
                    title={
                        <>
                            <Title level="h4">Transaction Types</Title>
                            <S.TxTypeTipsList>
                                <li>
                                    <strong>Confirmed</strong> - Any transaction
                                    with 35 confirmations on-chain.
                                </li>
                                <li>
                                    <strong>Upcoming</strong> - Any transaction
                                    due in the future.
                                </li>
                                <li>
                                    <strong>Uncollectible</strong> - Any
                                    unprocessed transaction with a due date that
                                    has passed and all attempts to automatically
                                    process have ended.
                                </li>
                                <li>
                                    <strong>Due</strong> - Any unprocessed
                                    transaction with a due date that has passed.
                                </li>
                                <li>
                                    <strong>Canceled</strong> - Payment requests
                                    that have been canceled.
                                </li>
                            </S.TxTypeTipsList>
                        </>
                    }
                    placement="bottom"
                >
                    <S.QIcon style={{ lineHeight: `1` }}>
                        <Question height="1rem" width="1rem" />
                    </S.QIcon>
                </S.TxTypeTips>
            </S.TypeRow>
            <TransactionView
                type={currentType}
                columns={config.columns}
                pagination={{ ...config.pagination, page }}
                sort={{ ...sort }}
                onSortChange={setUserSort}
                initialFilters={filters}
                onPageChange={setUserPage}
            />
        </>
    );
};

export default Transactions;
