import { useCallback } from "react";
import { useSafeApi } from "hooks/useSafeApi";
import {
    confirmEvmTransaction,
    confirmSolTransaction,
    sendEvmTokenPayment,
    sendSolTokenPayment,
} from "utils/tokens";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletManagement";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";
import { TransactionResponse } from "ethers";
import { SolProvider } from "types/common";

export interface SendTokenPaymentProps {
    token: Pick<BaseToken, "address" | "decimals" | "symbol"> &
        Partial<BaseToken>;
    amount: string;
    toAddress: string;
    nativeToken?: boolean;
}

export type WalletTransactionData = {
    walletAddress: string;
    txHash: string;
} & (
    | {
          chain: "EVM";
          transaction: TransactionResponse;
          isMultisig: false;
          provider?: undefined;
      }
    | {
          chain: "EVM";
          transaction?: undefined;
          isMultisig: true;
          provider?: undefined;
      }
    | {
          chain: "SOL";
          transaction?: undefined;
          isMultisig: false;
          provider: SolProvider;
      }
);

const confirmTransaction = async ({
    chain,
    txHash,
    transaction,
    provider,
}: WalletTransactionData): Promise<boolean> => {
    if (chain === "EVM" && transaction) {
        await confirmEvmTransaction(transaction);
        return true;
    } else if (chain === "SOL" && provider) {
        await confirmSolTransaction(provider, txHash);
        return true;
    } else {
        throw new Error("Invalid transaction data");
    }
};

const useWalletTransaction = (
    wallet: PrimaryWalletAccount | null,
    safeWallet: Promise<SafeWallet | null>
) => {
    const { sendSafeTokenPayment } = useSafeApi(wallet, safeWallet);

    const sendTokenPayment = useCallback(
        async ({
            token,
            amount: tokenAmount,
            toAddress,
            nativeToken = false,
        }: SendTokenPaymentProps): Promise<WalletTransactionData> => {
            if (!wallet?.address || !wallet.isConnected) {
                throw new Error("Wallet not connected");
            }

            try {
                if (wallet.chain === "EVM") {
                    if (wallet.proxyFor) {
                        const txHash = await sendSafeTokenPayment({
                            token,
                            amount: tokenAmount,
                            toAddress,
                            nativeToken,
                        });
                        return {
                            walletAddress: wallet.proxyFor,
                            isMultisig: true,
                            chain: "EVM",
                            txHash,
                        };
                    } else {
                        const transaction = await sendEvmTokenPayment({
                            signer: wallet.signer,
                            tokenAddress: token.address,
                            tokenAmount,
                            toAddress,
                            nativeToken,
                        });
                        return {
                            walletAddress: wallet.address,
                            isMultisig: false,
                            chain: "EVM",
                            transaction,
                            txHash: transaction.hash,
                        };
                    }
                } else {
                    const txHash = await sendSolTokenPayment({
                        provider: wallet.provider,
                        signer: wallet.signer,
                        tokenAddress: token.address,
                        tokenAmount,
                        toAddress,
                        nativeToken,
                    });
                    return {
                        walletAddress: wallet.address,
                        isMultisig: false,
                        chain: "SOL",
                        txHash,
                        provider: wallet.provider,
                    };
                }
            } catch (error: any) {
                if (error.code === "ACTION_REJECTED" || error.code === 4001) {
                    throw new Error("Transaction rejected by your wallet");
                } else {
                    throw new Error("Could not confirm transaction");
                }
            }
        },
        [
            sendSafeTokenPayment,
            wallet?.address,
            wallet?.signer,
            wallet?.provider,
            wallet?.chain,
            wallet?.proxyFor,
            wallet?.isConnected,
        ]
    );

    return { sendTokenPayment, confirmTransaction };
};

export default useWalletTransaction;
