import styled, { css } from "styled-components";
import {
    colorsDeprecated as c,
    sizes as s,
    properties as p,
} from "global-style";
import Panel from "components/Panel";
import Badge from "components/Badge";
import colors from "theme/colors";
import spacing from "theme/spacing";

export const Connected = styled.h3`
    display: none;
    grid-area: connected;
    margin: 0;
`;

export const WalletConnectPanel = styled(Panel)<{
    isWalletUsedAsLogin: boolean;
}>`
    display: grid;
    column-gap: 1rem;
    align-items: center;
    padding: 0.2em 0.6em;
    text-align: left;
    line-height: 1.1;
    padding-block: 0.5rem;

    ${({ isWalletUsedAsLogin }) =>
        !isWalletUsedAsLogin
            ? css`
                  grid-template-areas: "icon address edit disconnect" "safes safes safes safes" "other other other other";
                  grid-template-columns: auto 1fr auto auto;
                  @media screen and (min-width: ${s.sm}) {
                      grid-template-areas: "connected icon address edit disconnect" "safes safes safes safes safes" "other other other other other";
                      grid-template-columns: auto auto 1fr auto auto;
                      ${Connected} {
                          display: block;
                      }
                  }
              `
            : css`
                  grid-template-areas: "icon address edit" "safes safes safes" "other other other";
                  grid-template-columns: auto 1fr auto;
              `}
`;

export const WalletIcon = styled.img`
    grid-area: icon;
    height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
    align-self: center;
`;

export const MainAddress = styled.span`
    grid-area: address;
    display: flex;
    gap: 0.5rem;
    font-family: ${p.font.monospace};
`;

export const EditWallet = styled.span`
    grid-area: edit;
    display: flex;
    align-items: center;
    line-height: 1.2;
`;

export const DisconnectWallet = styled.span`
    grid-area: disconnect;
    display: flex;
    align-items: center;
    line-height: 1.2;
`;

export const SafeWallets = styled.section`
    grid-area: safes;
    margin-block-start: 0.5rem;
    padding-block-start: 0.5rem;
`;

export const OtherWallets = styled.section`
    grid-area: other;
    margin-block-start: 0.5rem;
    padding-block-start: 0.5rem;
    border-top: 1px solid ${c.border};
`;

export const Wallets = styled.ul`
    margin: 0;
`;

export const Address = styled.span<{ tabIndex?: any }>`
    display: flex;
    gap: 0.5rem;
    font-family: ${p.font.monospace};
    padding: 0.125rem ${spacing.xs};
    border-radius: ${p.radiusSm};
    &:not([disabled]):hover {
        cursor: pointer;
        background-color: ${c.darken};
    }
    &[disabled],
    &[data-proxying="true"] {
        color: ${colors.primary};
    }
    &:focus {
        outline: none;
    }
`;

export const OtherAddress = styled(Address)`
    padding: 0.125rem ${spacing.xxxxs};
    margin-inline-start: -${spacing.xxxxs};
`;

export const SafeLogo = styled.img`
    height: 1rem;
    vertical-align: middle;
    margin: 0 0.125rem 0.2rem;
`;

export const Proxying = styled(Badge)`
    display: flex;
    gap: 0.25rem;
    align-items: center;
    cursor: pointer;
    &::after {
        content: "x";
        display: inline-block;
        font-size: 0.8em;
        height: 1.2em;
        width: 1.2em;
        border-radius: 50%;
        background-color: ${c.darken};
        text-align: center;
    }
`;

export const WalletBrand = styled.li`
    margin-block: ${spacing.xs};
    padding-inline-start: ${spacing.xs};
`;

export const WalletIconSm = styled.img`
    grid-area: icon;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    align-self: center;
    margin-inline-end: ${spacing.xxs};
    vertical-align: bottom;
`;

export const Disconnected = styled(Badge)`
    cursor: pointer;
`;
