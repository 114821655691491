import { useCallback, useEffect, useState } from "react";
import {
    useDynamicContext,
    useDynamicEvents,
    useSwitchWallet,
    useUserWallets,
    useWalletConnectorEvent,
    Wallet as DynamicWallet,
} from "@dynamic-labs/sdk-react-core";
import { getSigner, getWeb3Provider } from "@dynamic-labs/ethers-v6";
import { isSolanaWallet } from "@dynamic-labs/solana";
import { BlockchainNetwork } from "default-variables";
import {
    EvmWalletSigner,
    EvmProvider,
    SolProvider,
    SolWalletSigner,
} from "types/common";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";

export interface WalletApp {
    walletId: string;
    icon: string | null;
    label: string;
}

interface PrimaryWalletAccountBase extends WalletApp {
    id: string;
    address: string;
    ens: string | null;
    proxyFor: string | null;
    safe: Promise<SafeWallet | null>;
    connect: () => Promise<void>;
}

export type SolWallet = {
    chain: "SOL";
} & (
    | {
          isConnected: true;
          signer: SolWalletSigner;
          provider: SolProvider;
      }
    | {
          isConnected: false;
          signer: null;
          provider: null;
      }
);

export type EvmWallet = {
    chain: "EVM";
} & (
    | {
          isConnected: true;
          signer: EvmWalletSigner;
          provider: EvmProvider;
      }
    | {
          isConnected: false;
          signer: null;
          provider: null;
      }
);

export type PrimaryWalletAccount = PrimaryWalletAccountBase &
    (SolWallet | EvmWallet);

export interface ConnectedWallet extends WalletApp {
    addresses: {
        id: string;
        address: string;
        chain: BlockchainNetwork;
        isConnected: boolean;
    }[];
}

// [ ] Is this still relevant?
/* As far as "walletsAvailable" go, it seems that Dynamic's "additionalAddresses" array is only set when
not in "connect-only" made (non-signatured). Thus, only the primary address of a wallet is available, 
rather than with Onboard, where each address/account in a wallet that was used would then be available
and could be switched to. Revisit this if we go with a premium account and use dynamic's signature */

const mapDynamicUserWalletsToConnectedWallets = async (
    wallets: DynamicWallet[]
): Promise<ConnectedWallet[]> => {
    return await wallets.reduce<Promise<ConnectedWallet[]>>(
        async (accPromise, wallet) => {
            const acc = await accPromise;

            const isConnected = await wallet.isConnected();
            const newAddress = {
                id: wallet.id,
                address: wallet.address,
                chain: wallet.connector.connectedChain as BlockchainNetwork,
                isConnected,
            };

            const existingWallet = acc.find((w) => w.walletId === wallet.key);

            if (existingWallet) {
                existingWallet.addresses = [
                    ...existingWallet.addresses,
                    newAddress,
                ];
            } else {
                const walletObj =
                    wallet.connector.constructorProps.walletBook.wallets[
                        wallet.key
                    ];

                return [
                    ...acc,
                    {
                        addresses: [newAddress],
                        icon: walletObj?.brand.spriteId
                            ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
                            : ``,
                        label: wallet.connector.name,
                        walletId: wallet.key,
                    },
                ];
            }
            return acc;
        },
        Promise.resolve([])
    );
};

const mapDynamicPrimaryWalletToConnectedWallet = async (
    wallet: DynamicWallet | null
): Promise<PrimaryWalletAccount | null> => {
    if (!wallet) return null;

    const isConnected = await wallet.isConnected();

    // [ ] Provider should be moved to the network object!!!
    const walletByChain = isSolanaWallet(wallet)
        ? isConnected
            ? {
                  chain: "SOL" as const,
                  isConnected: true as const,
                  signer: await wallet.getSigner(),
                  provider: await wallet.getConnection(),
              }
            : {
                  chain: "SOL" as const,
                  isConnected: false as const,
                  signer: null,
                  provider: null,
              }
        : isConnected
          ? {
                chain: "EVM" as const,
                isConnected: true as const,
                signer: await getSigner(wallet),
                provider: await getWeb3Provider(wallet),
            }
          : {
                chain: "EVM" as const,
                isConnected: false as const,
                signer: null,
                provider: null,
            };

    const walletObj =
        wallet.connector.constructorProps.walletBook.wallets[wallet.key];

    // [ ] connect() only works when NONE of the addresses in a wallet are connected to the site. Otherwise, hitting connect should switch to the address that is connected.

    const newWallet: PrimaryWalletAccountBase = {
        id: wallet.id,
        address: wallet.address,
        walletId: wallet.key,
        icon: walletObj?.brand.spriteId
            ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
            : ``,
        label: walletObj?.name ?? ``,
        ens: null,
        proxyFor: null,
        safe: Promise.resolve(null),
        connect: async () => await wallet.connector.connect(wallet.address),
    };

    return {
        ...newWallet,
        ...walletByChain,
    };
};

const useWalletManagement = (networkId: number | undefined) => {
    const [isWalletConnecting, setIsWalletConnecting] = useState(false);
    const [wallet, setWallet] = useState<PrimaryWalletAccount | null>(null);
    const [walletsAvailable, setWalletsAvailable] = useState<ConnectedWallet[]>(
        []
    );
    const { primaryWallet } = useDynamicContext();
    const switchWallet = useSwitchWallet();
    const userWallets = useUserWallets();

    const setPrimaryWallet = useCallback(
        (walletId: string) => {
            switchWallet(walletId);
        },
        [switchWallet]
    );

    // [ ] Move this to `useSafeWallets` and move `proxyFor` to a variable and privitize it, exposing "isProxying" and "getActiveAddress"
    const setProxyWallet = useCallback((proxyAddr: string | null) => {
        setWallet((prevWallet) => {
            if (!prevWallet) return null;

            return {
                ...prevWallet,
                proxyFor: proxyAddr,
            };
        });
    }, []);

    useEffect(() => {
        (async () => {
            const newWallets: ConnectedWallet[] =
                await mapDynamicUserWalletsToConnectedWallets(userWallets);

            setWalletsAvailable(newWallets);
        })();
    }, [userWallets]);

    useEffect(() => {
        if (!primaryWallet?.address) {
            setIsWalletConnecting(false);
            setWallet(null);
            return;
        }

        setIsWalletConnecting(true);
        (async () => {
            try {
                const newWallet =
                    await mapDynamicPrimaryWalletToConnectedWallet(
                        primaryWallet
                    );
                setWallet(newWallet);
            } catch (error) {
                console.error(`Failed to connect wallet`, error);
                setWallet(null);
                setIsWalletConnecting(false);
            }
        })();
    }, [
        primaryWallet?.id,
        primaryWallet?.address,
        primaryWallet?.connector,
        networkId,
        primaryWallet,
    ]);

    useEffect(() => {
        if (!wallet) return;

        setIsWalletConnecting(false);
    }, [wallet]);

    useDynamicEvents("logout", () => {
        console.log(`🤷‍♂️ LOGOUT EVENT`);
    });

    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "accountChange",
        async ({ accounts }) => {
            // [ ] THESE SHOULD ONLY UPDATE THE WALLETS THAT HAVE CHANGED, NOT ALL OF THEM!!!

            const newWallets: ConnectedWallet[] =
                await mapDynamicUserWalletsToConnectedWallets(userWallets);
            setWalletsAvailable(newWallets);

            const newWallet: PrimaryWalletAccount | null =
                await mapDynamicPrimaryWalletToConnectedWallet(primaryWallet);
            setWallet(newWallet);
        }
    );

    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "disconnect",
        async () => {
            // [ ] THESE SHOULD ONLY UPDATE THE WALLETS THAT HAVE CHANGED, NOT ALL OF THEM!!!

            const newWallets: ConnectedWallet[] =
                await mapDynamicUserWalletsToConnectedWallets(userWallets);
            setWalletsAvailable(newWallets);

            const newWallet: PrimaryWalletAccount | null =
                await mapDynamicPrimaryWalletToConnectedWallet(primaryWallet);

            setWallet(newWallet);
        }
    );

    return {
        isWalletConnecting,
        isWalletConnected: !!wallet?.address,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    };
};

export default useWalletManagement;
