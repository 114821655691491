import { useCallback, useEffect, useState } from "react";
import { AvailableNetwork } from "default-variables";
import { getEvmTokenBalance, getSolTokenBalance } from "utils/tokens";
import { SolProvider, EvmProvider } from "types/common";
import { useChainProvider } from "hooks/useChainProvider";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletManagement";

export interface WalletTokenBalance {
    networkId: string; // hex
    wallet: string;
    token: string;
    symbol: string;
    balance: string;
    updatedAt: number;
}

export interface TokenBalanceProps {
    tokenSymbol?: string | null; // [ ] Still necessary to support "null"?
    tokenAddress?: string | null; // [ ] Still necessary to support "null"?
    walletAddress?: string; // [ ] Can search other wallets, but must be on same chain and network
    networkId?: string;
}

export interface GetUpdatedTokenBalanceProps extends TokenBalanceProps {
    force?: boolean;
}

const balanceExpiryLimit = 3 * 60 * 1000; // 3 minutes

const useWalletBalance = (
    connectedWallet: PrimaryWalletAccount | null,
    connectedNetwork: AvailableNetwork | null
) => {
    const [balances, setBalances] = useState<WalletTokenBalance[]>([]);
    const { getProviderForAnyWalletAndNetwork } = useChainProvider(
        connectedWallet,
        connectedNetwork
    );

    const getStoredTokenBalanceIndex = useCallback(
        ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
        }: TokenBalanceProps): {
            existingIndex: number;
            wallet: string;
            network: AvailableNetwork;
        } => {
            const { wallet, network } = getProviderForAnyWalletAndNetwork({
                walletAddress,
                networkId,
            });

            // Use the wallet's address to represent the native token address
            const tokenAddressForStorage = tokenAddress || wallet;

            // Use the default token if no symbol is provided
            const tokenSymbolForStorage = tokenSymbol || network.token;

            const existingIndex = balances.findIndex(
                (existing) =>
                    existing.token === tokenAddressForStorage &&
                    existing.symbol === tokenSymbolForStorage &&
                    existing.wallet === wallet &&
                    existing.networkId === network.networkId
            );

            return { existingIndex, wallet, network };
        },
        [balances, getProviderForAnyWalletAndNetwork]
    );

    const fetchTokenBalance = useCallback(
        async ({
            tokenAddress,
            walletAddress,
            networkId,
        }: Omit<TokenBalanceProps, "tokenSymbol">): Promise<string> => {
            const { chainAndProvider, wallet } =
                getProviderForAnyWalletAndNetwork({
                    walletAddress,
                    networkId,
                });

            return chainAndProvider.chain === "SOL"
                ? await getSolTokenBalance({
                      tokenAddress: tokenAddress || wallet,
                      walletAddress: wallet,
                      provider: chainAndProvider.provider as SolProvider,
                  })
                : await getEvmTokenBalance({
                      tokenAddress,
                      walletAddress: wallet,
                      provider: chainAndProvider.provider as EvmProvider,
                  });
        },
        [getProviderForAnyWalletAndNetwork]
    );

    const hasTokenBalanceStored = useCallback(
        ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
        }: TokenBalanceProps): boolean => {
            const { existingIndex } = getStoredTokenBalanceIndex({
                tokenSymbol,
                tokenAddress,
                walletAddress,
                networkId,
            });

            return existingIndex !== -1;
        },
        [getStoredTokenBalanceIndex]
    );

    const getTokenBalance = useCallback(
        async ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
            force = false,
        }: GetUpdatedTokenBalanceProps = {}): Promise<string> => {
            try {
                const { wallet, network } = getProviderForAnyWalletAndNetwork({
                    walletAddress,
                    networkId,
                });

                // Check cache first
                const cachedBalance = await new Promise<string | null>(
                    (resolve) => {
                        setBalances((prevBalances) => {
                            const existingBalance = prevBalances.find(
                                (existing) =>
                                    existing.token ===
                                        (tokenAddress || wallet) &&
                                    existing.symbol ===
                                        (tokenSymbol || network.token) &&
                                    existing.wallet === wallet &&
                                    existing.networkId === network.networkId
                            );

                            if (
                                !force &&
                                existingBalance &&
                                existingBalance.updatedAt + balanceExpiryLimit >
                                    Date.now()
                            ) {
                                resolve(existingBalance.balance);
                            } else {
                                resolve(null);
                            }
                            return prevBalances;
                        });
                    }
                );

                if (cachedBalance) {
                    return cachedBalance;
                }

                // If no cache or force refresh, fetch new balance
                const balance = await fetchTokenBalance({
                    tokenAddress,
                    walletAddress,
                    networkId,
                });

                setBalances((prevBalances) => {
                    const existingIndex = prevBalances.findIndex(
                        (existing) =>
                            existing.token === (tokenAddress || wallet) &&
                            existing.symbol ===
                                (tokenSymbol || network.token) &&
                            existing.wallet === wallet &&
                            existing.networkId === network.networkId
                    );

                    const tokenAddressForStorage = tokenAddress || wallet;
                    const tokenSymbolForStorage = tokenSymbol || network.token;

                    return existingIndex !== -1
                        ? prevBalances.map((item, index) =>
                              index === existingIndex
                                  ? { ...item, balance, updatedAt: Date.now() }
                                  : item
                          )
                        : [
                              ...prevBalances,
                              {
                                  networkId: network.networkId,
                                  wallet,
                                  token: tokenAddressForStorage,
                                  symbol: tokenSymbolForStorage,
                                  balance,
                                  updatedAt: Date.now(),
                              },
                          ];
                });

                return balance;
            } catch (error) {
                throw new Error(
                    `There was a problem checking your wallet's ${tokenSymbol} balance: ${error}`
                );
            }
        },
        [fetchTokenBalance, getProviderForAnyWalletAndNetwork]
    );

    return { getTokenBalance, hasTokenBalanceStored };
};

export default useWalletBalance;
