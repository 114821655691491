import * as S from "./style";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    DynamicEmbeddedWidget,
    useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
import loopLogo from "assets/img/logos/loop-crypto-long.svg";
import { apiServerUrl, reactAppBuildType } from "default-variables";
import { getErrorMessage } from "utils/errors";
import { useUser, UserRole, tierStrToEnum } from "context/User";
import { useWallet } from "context/Wallet";
import Loading from "components/Loading";
import { NotificationType } from "components/Notification";
import { useNotificationQueue } from "context/NotificationQueue";
import { useHistoryState } from "hooks/useHistoryState";
import useShadowDomInjector from "hooks/useShadowDomInjector";

export interface LogUserInResponse {
    success: boolean;
    email: string | null; // [ ] Adding `null` here might break things, have a look
    entityId: string;
    delegatedSigning: boolean;
    companyName: string;
    url: string;
    logoUrl: string;
    token: string;
    roles: string[];
}

export interface LoginContextData {
    redirectPath?: string;
}

const LoginDynamic = () => {
    const { user } = useDynamicContext();
    const { addNotification } = useNotificationQueue();
    const { newUserSession } = useUser();
    const { isUserLoggedIn, walletSessionToken, handleUserLogout } =
        useWallet();
    const navigate = useNavigate();
    const { from: redirectPath } = useHistoryState(true);
    const [loading, setLoading] = useState(false);

    // It appears this can also be handled using `onAuthSuccess` callback handler:
    //   https://docs.dynamic.xyz/sdks/react-sdk/events/onauthsuccess
    useEffect(() => {
        if (!isUserLoggedIn || !walletSessionToken || !user?.userId) return;

        setLoading(true);

        (async () => {
            let res, data;
            try {
                res = await fetch(`${apiServerUrl}/v1/user/login`, {
                    method: `POST`,
                    body: JSON.stringify({
                        token: walletSessionToken,
                        id: user.userId,
                    }),
                });
                data = await res.json();

                if (!res.ok) {
                    throw await data;
                }
            } catch (error: unknown) {
                const errorMsg = getErrorMessage(error);
                addNotification({
                    msg: errorMsg,
                    type: NotificationType.ERROR,
                });

                await handleUserLogout();
                return;
            } finally {
                setLoading(false);
            }

            const {
                token,
                entityId,
                companyName,
                roles,
                delegatedSigning,
                email,
            }: LogUserInResponse = data;

            newUserSession({
                token,
                roles: roles as UserRole[],
                entityId,
                tier: tierStrToEnum(`guest`),
                companyName,
                email,
                delegatedSigning,
            });

            navigate(redirectPath || `/`);
        })();
    }, [
        isUserLoggedIn,
        walletSessionToken,
        user?.userId,
        redirectPath,
        addNotification,
        newUserSession,
        handleUserLogout,
        navigate,
    ]);

    const loginTopperComponent = useMemo(
        () => (
            <S.LoginTopper>
                <S.LongLogo src={loopLogo} alt="Loop Crypto" />
                <S.EnvBadge />
            </S.LoginTopper>
        ),
        []
    );

    useShadowDomInjector({
        shadowHostSelector: ".dynamic-shadow-dom.embedded-widget",
        targetSelector: ".modal-header",
        component: loginTopperComponent,
        position: "before",
    });

    return (
        <S.LoginDynamic environment={reactAppBuildType}>
            {loading ? (
                <Loading />
            ) : (
                <S.Container>
                    <DynamicEmbeddedWidget
                        background="with-border"
                        className="loop-dynamic-embedded-login"
                    />
                </S.Container>
            )}
        </S.LoginDynamic>
    );
};

export default LoginDynamic;
