import { useEffect, ReactElement } from "react";
import { createRoot } from "react-dom/client";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";

interface ShadowDomInjectorOptions {
    shadowHostSelector: string;
    targetSelector: string;
    component: ReactElement;
    position?: "replace" | "prepend" | "append" | "before" | "after";
}

const useShadowDomInjector = ({
    shadowHostSelector,
    targetSelector,
    component,
    position = "replace",
}: ShadowDomInjectorOptions) => {
    useEffect(() => {
        let root: ReturnType<typeof createRoot> | null = null;
        const sheet = new ServerStyleSheet();

        try {
            // Observer for content within shadow root
            const shadowObserver = new MutationObserver(() => {
                const shadowHost = document.querySelector(shadowHostSelector);
                if (!shadowHost?.shadowRoot) return;

                const target =
                    shadowHost.shadowRoot.querySelector(targetSelector);
                if (!target) return;

                if (!target.hasAttribute("data-injected")) {
                    let styleElement = shadowHost.shadowRoot.querySelector(
                        'style[data-injected="true"]'
                    ) as HTMLStyleElement;

                    if (!styleElement) {
                        styleElement = document.createElement("style");
                        styleElement.setAttribute("data-injected", "true");
                        shadowHost.shadowRoot.appendChild(styleElement);
                    }

                    target.setAttribute("data-injected", "true");

                    let container: Element;
                    if (position === "replace") {
                        container = target;
                    } else if (position === "before" || position === "after") {
                        const parent = target.parentElement;
                        if (!parent) return;

                        container = document.createElement("div");
                        if (position === "before") {
                            parent.insertBefore(container, target);
                        } else {
                            parent.insertBefore(container, target.nextSibling);
                        }
                    } else {
                        container = document.createElement("div");
                        if (position === "prepend") {
                            target.prepend(container);
                        } else {
                            target.append(container);
                        }
                    }

                    root?.unmount();
                    root = createRoot(container as HTMLElement);
                    root.render(
                        <StyleSheetManager target={styleElement}>
                            {component}
                        </StyleSheetManager>
                    );
                }
            });

            // Observer for shadow root creation/recreation
            const hostObserver = new MutationObserver(() => {
                const shadowHost = document.querySelector(shadowHostSelector);
                if (shadowHost?.shadowRoot) {
                    shadowObserver.observe(shadowHost.shadowRoot, {
                        childList: true,
                        subtree: true,
                    });
                }
            });

            // Start observing for shadow host
            hostObserver.observe(document.body, {
                childList: true,
                subtree: true,
            });

            // Initial observation if shadow root exists
            const shadowHost = document.querySelector(shadowHostSelector);
            if (shadowHost?.shadowRoot) {
                shadowObserver.observe(shadowHost.shadowRoot, {
                    childList: true,
                    subtree: true,
                });
            }

            return () => {
                hostObserver.disconnect();
                shadowObserver.disconnect();
                root?.unmount();
                sheet.seal();
            };
        } finally {
            sheet.seal();
        }
    }, [shadowHostSelector, targetSelector, component, position]);
};

export default useShadowDomInjector;
