import { ComponentType } from "react";
import {
    DynamicContextProps,
    DynamicContextProvider,
} from "@dynamic-labs/sdk-react-core";
import { SdkViewType, SdkViewSectionType } from "@dynamic-labs/sdk-api-core";

const withDynamicContext = <
    T extends object & { requiresDynamicLogin?: boolean },
>(
    ContextProvider: ComponentType<T>,
    settings: DynamicContextProps["settings"]
) => {
    return (props: T) => {
        if (!props.requiresDynamicLogin) {
            // CONNECT-ONLY SETTINGS
            settings = {
                ...settings,
                initialAuthenticationMode: "connect-only",
                cssOverrides: `
                    ${settings.cssOverrides}
                    /* Hide email when connecting with wallet only */
                    .login-with-email-form {
                        display: none;
                    }
                    .login-view__scroll__section:has(.login-with-email-form) + .login-view__scroll__section--separator {
                        display: none;
                    }
                        
                    /* Change "Login or Signup" */
                    [copykey="dyn_wallet_list.title.connect"]::after, [copykey="dyn_login.title.all"]::after {
                        content: "Connect a wallet";
                    }
                `,
            };
        } else {
            // CONNECT-AND-SIGN SETTINGS
            settings = {
                ...settings,
                initialAuthenticationMode: "connect-and-sign",
                cssOverrides: `
                    ${settings.cssOverrides}
                    .modal-header {
                        padding-top: 0;
                    }
                    [copykey="dyn_wallet_list.title.connect"], [copykey="dyn_login.title.all"] {
                        display: none;
                    }
                `,
                overrides: {
                    ...settings.overrides,
                    views: [
                        ...(settings.overrides?.views || []),
                        {
                            type: SdkViewType.Login,
                            sections: [
                                {
                                    type: SdkViewSectionType.Email,
                                },
                                {
                                    type: SdkViewSectionType.Separator,
                                    label: "OR",
                                },
                                {
                                    type: SdkViewSectionType.Wallet,
                                    numOfItemsToDisplay: 2,
                                },
                            ],
                        },
                    ],
                },
            };
        }

        return (
            <DynamicContextProvider settings={settings}>
                <ContextProvider {...props} />
            </DynamicContextProvider>
        );
    };
};

export default withDynamicContext;
