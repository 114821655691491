import styled from "styled-components";
import { sizes as s } from "global-style";
import Anchor from "components/Anchor";
import Tooltip from "components/Tooltip";
import colors from "theme/colors";
import spacing from "theme/spacing";

export const TypeRow = styled.nav`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-block-end: ${spacing.md};
`;

export const LearnMore = styled(Anchor)`
    color: inherit;
`;

export const QIcon = styled.span`
    display: none;
    @media screen and (min-width: ${s.md}) {
        display: inline;
    }
`;

export const TxTypeTips = styled(Tooltip)`
    display: none;
    @media screen and (min-width: ${s.md}) {
        display: block;
    }
`;

export const Attention = styled.span`
    color: ${colors.error};
`;

export const SplitCol = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    @media screen and (min-width: ${s.md}) {
        justify-content: unset;
    }
`;

export const NoWrap = styled.span`
    white-space: nowrap;
`;

export const TxTypeTipsList = styled.ul`
    li {
        margin-block: ${spacing.xs};
    }
`;
