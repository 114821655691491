import { useCallback } from "react";
import { TransactionValues } from "company/types";
import { useTransactionTable } from "company/routes/Transactions/hooks/useTransactionTable";
import Table from "components/Table";
import Section from "components/Section";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import Anchor from "components/Anchor";
import EmptyTableMessage from "components/EmptyTableMessage";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import spacing from "theme/spacing";
import {
    TransactionType,
    TransactionFilters,
} from "company/routes/Transactions/types";

interface TransactionViewProps {
    type: TransactionType | undefined;
    columns: TransactionValues[];
    pagination: Pagination;
    sort: SortBy;
    onSortChange: (sort: SortBy) => void;
    initialFilters?: TransactionFilters;
    onPageChange: (page: number) => void;
}

const TransactionView = ({
    type,
    columns,
    pagination,
    sort,
    onSortChange,
    initialFilters,
    onPageChange,
}: TransactionViewProps) => {
    const {
        totalResults,
        headings,
        records,
        transactionsIsLoading,
        transactionsIsError,
        transactionsIsFetching,
        transactionsRefetch,
        transactionsDataUpdatedAt,
    } = useTransactionTable({
        type,
        columns,
        filters: initialFilters,
        sort,
        page: pagination.page,
        perPage: pagination.perPage,
    });

    const handleSortChange = useCallback(
        (_: any, direction?: SortBy) => {
            if (!direction) return;
            onSortChange({ ...direction });
            onPageChange(1);
        },
        [onSortChange, onPageChange]
    );

    // [ ] transactionsRefetch does not invalidate the entire view, only the exact page refreshed on

    return (
        <Section>
            <RefreshCounter
                refreshedAt={transactionsDataUpdatedAt}
                counter={CounterType.Running}
                onRefresh={transactionsRefetch}
                refreshing={transactionsIsFetching}
            />
            {transactionsIsLoading ? (
                <LoadingBox
                    height={spacing.md}
                    placeholderStyle={LoadingPlaceholderStyle.Table}
                    tablePlaceholderRows={pagination.perPage}
                    tablePlaceholderCols={columns.length}
                />
            ) : transactionsIsError ? (
                <FailedDataFetchingMessage />
            ) : (
                <Table
                    defaultSort={sort}
                    refetching={transactionsIsFetching}
                    pagination={{
                        page: pagination.page,
                        perPage: pagination.perPage,
                    }}
                    externalPageAndSort={true}
                    totalRecords={totalResults}
                    data={{
                        headings,
                        records,
                    }}
                    onPageChange={onPageChange}
                    onSort={handleSortChange}
                    ifNoRecords={
                        <EmptyTableMessage
                            title="No transactions found"
                            description={
                                <>
                                    Need help? Take a look at our{" "}
                                    <Anchor
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_SENDING_PAYMENT_REQUESTS
                                        }
                                    >
                                        documentation
                                    </Anchor>{" "}
                                    or{" "}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                />
            )}
        </Section>
    );
};

export default TransactionView;
